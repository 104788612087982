<template>
    <!-- <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail">
            <div>
                <img :src="productDate.productImage" :alt="productDate.productName" @load="$emit('handleImageLoad')">
            </div>
            <countdown v-if="productDate.countdown" :date="productDate.countdown.date"/>
            <router-link v-if="showPlaceBid" :to="`/product/${productDate.id}`" class="btn btn-primary">
                Place Bid
            </router-link> 
        </div>
        <div class="product-share-wrapper">
            <div class="profile-share">
                <router-link v-for="(author, index) in productDate.authors"
                             :key="`author-${index}`"
                             :to="`/author/${author.id}`"
                             class="avatar"
                             :data-tooltip="author.name">
                    <img :src="author.image" :alt="author.name">
                </router-link>
                <router-link to="#" class="more-author-text">
                    {{ productDate.biddingAmount }}+ Place Bit.
                </router-link>
            </div>
            <div class="share-btn share-btn-activation dropdown">
                <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="feather-more-horizontal"/>
                </button>
                <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                    <button type="button"
                            class="btn-setting-text share-text"
                            data-bs-toggle="modal"
                            data-bs-target="#shareModal">
                        Share
                    </button>
                    <button type="button"
                            class="btn-setting-text report-text"
                            data-bs-toggle="modal"
                            data-bs-target="#reportModal">
                        Report
                    </button>
                </div>
            </div>
        </div>
        <router-link :to="`/product/${productDate.id}`">
            <span class="product-name">{{ productDate.productName }}</span>
        </router-link>
        <div>
            <span class="product-name">{{ productDate.productName }}</span>
        </div>
        <span class="latest-bid">{{ productDate.latestBid }}</span>
        <div class="bid-react-area">
            <div class="last-bid">{{ productDate.lastBid }}</div>
            <div class="react-area">
                <i class="feather-heart"/>
                <span class="number">{{ productDate.reacted }}</span>
            </div>
        </div>
    </div> -->
    <div class="price_cards">
        <div class="card" :class="`product-style-one ${productStyleClass}`">
            <h2 class="card-name">
                <span v-if="!moreDisk">
                    {{ productDate.productName }}
                </span>
                <span v-else>
                    {{ productDate.more_productName }}
                </span>
            </h2>
            <div class="card-params">
                <ul class="card-params-list">
                    <li>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#00FF80" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h6 v-if="productDate.params.more_processor && moreDisk">
                            {{ productDate.params.more_processor }}
                        </h6>
                        <h6 v-else>
                            {{ productDate.params.processor }}
                        </h6>
                    </li>
                    <li>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#00FF80" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h6 v-if="productDate.params.more_RAM && moreDisk">
                            {{ productDate.params.more_RAM }}
                        </h6>
                        <h6 v-else>
                            {{ productDate.params.RAM }}
                        </h6>
                    </li>
                    <li>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#00FF80" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h6 v-if="productDate.params.more_disk && moreDisk">
                            {{ productDate.params.more_disk }}
                        </h6>
                        <h6 v-else>
                            {{ productDate.params.disk }}
                        </h6>
                    </li>
                    <li>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#00FF80" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h6>
                            {{ productDate.params.OC }}
                        </h6>
                    </li>
                </ul>
            </div>
            <div class="card-button">
                <router-link to="/activate">
                    <h6 v-if="!moreDisk">
                        от {{ productDate.price }} ₽/мес.
                    </h6>
                    <h6 v-else>
                        от {{ productDate.more_price }} ₽/мес.
                    </h6>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Countdown from '@/components/product/Countdown';

export default {
    name: 'ProductCard',
    components: { Countdown },
    props: {
        productDate: {},
        productStyleClass: {
            type: String
        },
        showPlaceBid: {
            type: Boolean,
            default: false
        },
        moreDisk: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        '$route': function (to, from) {
            this.closeModal()
        }
    },
    methods: {
        closeModal() {
            const modals = document.querySelectorAll('.modal-backdrop');

            if (modals.length) {
                modals.forEach(item => {
                    item.remove();
                })
            }
        }
    }
}
</script>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
}

.price_cards {
    .card {
        padding: 30px;

        .card-name {
            /* color: var(--color-heading); */
            font-size: 17px;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 0px;
            text-align: center;
        }

        border-radius: 9px;
        /* background: var(--background-color-2); */

        .card-params {
            .card-params-list {
                display: grid;
                gap: 16px;
                margin: 30px 0;
                grid-template-columns: 1fr 1fr;

                li {
                    display: flex;
                    gap: 10px;
                    margin: 0;

                    /* color: var(--color-heading); */
                    h6 {
                        margin: 0;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
            }
        }

        .card-button {
            a {
                padding: 0;

                h6 {
                    border: 1px solid #2424354a;
                    border-radius: 6px;
                    text-align: center;
                    padding: 10px 0px;
                    background: rgba(255, 255, 255, 0.10);
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0;
                }
            }
        }
    }
}
</style>