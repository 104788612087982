<template>
    <div class="vedio-area rn-section-gapTop">
        <div class="container">
            <div class="row">
                <div class="col-12 position-relative">
                    <a id="play-video" class="video-play-button btn-large with-animation" href="#"
                        @click.prevent="playVideo">
                        <span />
                    </a>

                    <!-- Start vedio image wrapper -->
                    <div class="vedio-wrapper">
                        <img :src="require(`@/assets/images/slider/main-2.png`)" alt="Video thumbnail">
                    </div>
                    <!-- End vedio image wrapper -->

                    <div id="video-overlay" class="video-overlay" @click="closeVideo">
                        <a class="video-overlay-close" @click.prevent="closeVideo">×</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoStyleOne',
    props: {
        videoUrl: {
            type: String
        }
    },
    methods: {
        playVideo() {
            const videoOverlay = document.querySelector('#video-overlay');
            let iframeElement = document.createElement('iframe');

            iframeElement.setAttribute('src', this.videoUrl);
            iframeElement.style.width = '60%';
            iframeElement.style.height = '80%';

            videoOverlay?.classList.add('open');
            videoOverlay.appendChild(iframeElement);
        },
        closeVideo() {
            const videoOverlay = document.querySelector('#video-overlay.open'),
                iframeElement = document.querySelector('#video-overlay.open iframe');

            videoOverlay?.classList.remove('open');
            iframeElement.remove();
        }
    }
}
</script>


<style>
.vedio-area.rn-section-gapTop {
    padding-top: 0;
}

.vedio-wrapper img {
    background: none !important;
}
</style>