<template>
    <div class="rn-product-area rn-section-gapTop">
        <div class="container">
            <div class="row mb--10 align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--70" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        Тарифы
                    </h3>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                    <div class="view-more-btn text-start text-sm-end">
                        <button class="discover-filter-button btn btn-primary"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                data-sal-delay="150"
                                data-sal="slide-up"
                                data-sal-duration="800">
                            Filter <i class="feather-filter"/>
                        </button>
                    </div>
                </div> -->

                <div class="switcher-wrapper">
                    <div class="tarif-switcher">
                        <button class="btn btn-large btn-primary sal-animate tarif-switcher-btn" @click="switchTariffOn"
                            :class="{ 'switcher-background': !chosenBackground }">
                            Базовые тарифы
                        </button>

                        <button class="btn btn-large btn-primary sal-animate tarif-switcher-btn"
                            @click="switchTariffOff" :class="{ 'switcher-background': chosenBackground }">
                            Больше диска
                        </button>
                    </div>
                </div>
            </div>

            <!-- <div class="collapse default-exp-wrapper" id="collapseExample">
                <div class="inner">
                    <div class="filter-select-option">
                        <label class="filter-leble">LIKES</label>
                        <nice-select
                            :options="[
                                    { value: 'most-liked', text: 'Most liked' },
                                    { value: 'least-liked', text: 'Least liked' }
                                ]"
                            :default-current="0"
                            placeholder="Sort by likes"
                            name="like"
                            @onChange="changeLikes"
                        />
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Category</label>
                        <nice-select
                            :options="[
                                    {value: 'all', text: 'All Category'},
                                    {value: 'art', text: 'Art'},
                                    {value: 'music', text: 'Music'},
                                    {value: 'video', text: 'Video'},
                                    {value: 'collectionable', text: 'Collectionable'}
                                ]"
                            :default-current="0"
                            placeholder="Category"
                            name="category"
                            @onChange="changeCategory"
                        />
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Collections</label>
                        <nice-select
                            :options="[
                                    {value: 'all', text: 'All Collection'},
                                    {value: 'Art Decco', text: 'Art Decco'},
                                    {value: 'BoredApeYachtClub', text: 'BoredApeYachtClub'},
                                    {value: 'MutantApeYachtClub', text: 'MutantApeYachtClub'},
                                    {value: 'Art Blocks Factory', text: 'Art Blocks Factory'}
                                ]"
                            :default-current="0"
                            placeholder="Collections"
                            name="collection"
                            @onChange="changeCollection"
                        />
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Sale type</label>
                        <nice-select
                            :options="[
                                    {value: 'all', text: 'All Type'},
                                    {value: 'fixed-price', text: 'Fixed price'},
                                    {value: 'timed-auction', text: 'Timed auction'},
                                    {value: 'not-for-sale', text: 'Not for sale'},
                                    {value: 'open-for-offers', text: 'Open for offers'}
                                ]"
                            :default-current="0"
                            placeholder="Sale type"
                            name="sale_type"
                            @onChange="changeSaleType"
                        />
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Price Range</label>
                        <div class="price_filter s-filter clear">
                            <form action="#" method="GET">
                                <range-slider
                                    @onChangeRange="onChangeRange"
                                    @filterPrice="filterPrice"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row g-5" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                <template v-for="(product, index) in filteredProducts" :key="`product-item-${index}`">
                    <div v-if="index < 10" class="col-5 col-lg-4 col-md-6 col-sm-6 col-12">
                        <product-card :product-date="product" :moreDisk="moreDisk" product-style-class="no-overlay"
                            :show-countdown="false" />
                    </div>
                </template>
                <!-- <h3 v-if="!filteredProducts.length" class="text-center">No Match Found</h3> -->
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/components/product/ProductCard'
import NiceSelect from '@/components/select/NiceSelect'
import RangeSlider from '@/components/range/RangeSlider'
import ProductFilterMixin from '@/mixins/ProductFilterMixin'
export default {
    name: 'ExploreFilter',
    components: { RangeSlider, NiceSelect, ProductCard },
    mixins: [ProductFilterMixin],
    data() {
        return {
            chosenBackground: true,
            moreDisk: false
        }
    },
    methods: {
        switchTariffOn() {
            this.chosenBackground = true
            this.moreDisk = false
        },
        switchTariffOff() {
            this.chosenBackground = false
            this.moreDisk = true
        }
    }
}
</script>


<style>
@media only screen and (min-width: 1400px) {
    .col-5 {
        flex: 0 0 auto;
        width: 33.3333333% !important;
    }
}

.switcher-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 22px;

    .tarif-switcher {
        display: flex;
        justify-content: center;
        background: #242435;
        max-width: 450px !important;
        width: 100% !important;
        padding: 8px;
        border-radius: 8px;

        .tarif-switcher-btn {
            max-width: 214px;
            width: 100%;
        }

        .tarif-switcher-btn {
            background: rgba(255, 255, 255, 0.10);
        }

        .tarif-switcher-btn:hover {
            background: rgba(255, 255, 255, 0.10);
            cursor: default;
        }

        .switcher-background {
            background: #242435;
        }
        
        .switcher-background:hover {
            background: transparent;
            cursor: pointer;
        }
    }
}

.active-light-mode {
    .tarif-switcher {
        display: flex;
        justify-content: center;
        background: #fff;
        max-width: 450px !important;
        width: 100% !important;
        padding: 8px;
        border-radius: 8px;

        .tarif-switcher-btn {
            max-width: 214px;
            width: 100%;
        }

        .tarif-switcher-btn {
            background: #000;
        }

        .tarif-switcher-btn:hover {
            background: #00000084;
        }

        .switcher-background {
            background: #fff;
            color: #000;
            cursor: default;
        }

        .switcher-background:hover {
            color: #000 !important;
            background: #fff;
        }
    }
}
</style>