<template>
    <layout>
        <breadcrumb title="Follow Privacy Policy" current="Follow Privacy Policy"/>

        <div class="rn-privacy-policy-area rn-section-gapTop">
            <div class="container">
                <div class="row mb_dec--50">
                    <div class="offset-lg-2 col-lg-8 ">
                        <div class="privacy-wrapper">
                            <h4>Welcome to HighNet Privacy Policy</h4>
                            <ol>
                                <li>
                                    Where Bitcoin was hailed as the digital answer to currency, NFTs are now being
                                    touted as
                                    the digital answer to collectables, but plenty of sceptics fear they're a bubble
                                    waiting
                                    to burst.
                                </li>
                                <li>
                                    It could be a house, or a painting such as the Mona Lisa, which is one of a kind.
                                    You
                                    can take a photo of the painting or buy a print but there will only ever be one
                                    original
                                    painting.
                                </li>
                                <li>
                                    Former Christie's auctioneer Charles Allsopp said the concept of buying NFTs made
                                    "no
                                    sense".

                                    "The idea of buying something which isn't there is just strange," he told the BBC.
                                </li>
                            </ol>

                            <h4>The type of personal information we collect</h4>
                            <ol>
                                <li>
                                    It might sound ridiculous but the explosive market of crypto-collectibles and
                                    crypto-art
                                    is no joke. I investigate cryptocurrencies and have academic publications on Bitcoin
                                    markets. To help you understand what an NFT is and why they’re becoming so popular,
                                    here’s an explainer to make sense of it all.
                                </li>
                                <li>
                                    The bidding on Musk’s tweet has already topped $1 million and millions more are
                                    pouring
                                    into the market — he has since tweeted, “Actually, doesn’t feel quite right selling
                                    this. Will pass.” And sites like NBA Top Shot (where you can buy, sell and trade
                                    digital
                                    NBA cards) have individual cards selling for over US$200,000.
                                </li>
                                <li>
                                    A non-fungible token (NFT) is a digital file with verified identity and ownership.
                                    This
                                    verification is done using blockchain technology. Blockchain technology, simply put,
                                    is
                                    an un-hackable system based on the mathematics of cryptography. So, that’s why you
                                    hear
                                    a lot of “crypto” when referring to NFTs — crypto-art, crypto-collectibles, etc.
                                </li>
                            </ol>
                            <h4>How we collect personal information</h4>
                            <ol>
                                <li>
                                    Poised to radically reconfigure the crypto-asset market, non-fungible tokens, or
                                    NFTs,
                                    are revolutionizing our conception of money and value, creating not just entirely
                                    new
                                    markets but even new economies that are able to scale globally and to discover value
                                    in
                                    undreamt places, relegating to oblivion fiat currencies and old ways of doing
                                    business.
                                </li>
                                <li>
                                    Just kidding. The previous paragraph is a parody (if such is possible) of the hype
                                    that
                                    in the first half of has come to surround non-fungible tokens. Indeed, the hype has
                                    become so overpowering that it may even defy parody. Non-fungible tokens can have
                                    legitimacy, and I’ll discuss how that can be at the end of this article. But for now
                                    the
                                    overwhelming majority of what passes for NFTs is delusion, fueled by the hope of a
                                    quick
                                    return and the belief that something can be gotten for nothing (or virtually
                                    nothing).
                                </li>
                                <li>
                                    But digital collectibles, whatever else they may be, are—without exception or
                                    remainder—digital files. In other words, they’re just strings of bits. As a string
                                    of
                                    bits, a digital file has a fixed number of bits, with the bit at any location in the
                                    string having a definite value of either zero or one.
                                </li>
                            </ol>
                            <h4>Collect personal information</h4>
                            <ol>
                                <li>
                                    Fungibility is the ability of an asset to be interchanged with other individual
                                    assets
                                    of the same kind; it implies equal value between the assets. If you own a fungible
                                    asset
                                    you can readily interchange it for another of a similar kind. Fungible assets
                                    simplify
                                    the exchange and trade processes, and the best example would be (you guessed it)
                                    money.

                                </li>
                                <li>
                                    Incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </li>
                                <li>
                                    This is where I can explain and emphasize the “non-fungibility” property of NFTs.
                                    The
                                    main difference between NFTs and Bitcoins is the fact that Bitcoins are limited, and
                                    fungible (you can trade one Bitcoin with another and both have the same value and
                                    price). NFTs are unique but unlimited, and non-fungible (no two artworks are the
                                    same).
                                    While NFTs can appreciate in value (just like real estate), they cannot be
                                    interchanged
                                    for another NFT.
                                </li>
                                <li>
                                    Iipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure
                                    dolor in reprehenderit in voluptate velit.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row mt--50">
                    <div class="offset-lg-2 col-lg-8">
                        <router-link to="#" class="btn btn-primary mr--15 ml--25">Accept</router-link>
                        <router-link to="#" class="btn btn-primary-alta">Decline</router-link>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb'

    export default {
        name: "PrivacyPolicy",
        components: {Breadcrumb, Layout}
    }
</script>