<template>
    <div class="rn-service-one color-shape-7" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
        <div class="inner">
            <div class="icon">
                <img :src="serviceData.image" alt="Shape">
            </div>
            <div class="subtitle">{{ serviceData.subtitle }}</div>
            <div class="content">
                <h4 class="title">
                    <router-link to="/activate" v-html="serviceData.title"></router-link>
                </h4>
                <p class="description" v-html="serviceData.description"></p>
                <!-- <router-link class="read-more-button" :to="serviceData.link">
                    <i class="feather-arrow-right"/>
                </router-link> -->
            </div>
        </div>
        <!-- <router-link class="over-link" :to="serviceData.link"></router-link> -->
    </div>
</template>


<script>
export default {
    name: 'ServiceCard',
    props: {
        serviceData: {}
    }
}
</script>


<style>
.active-light-mode {
    .rn-service-one {
        .inner {
            .icon {
                img {
                    filter: brightness(0.5);
                }
            }

            .title {
                color: #000 !important;
            }
        }
    }
}


.active-dark-mode {
    .rn-service-one {
        .inner {
            .icon {
                img {
                    filter: brightness(0.5);
                }
            }

            .title {
                color: #fff !important;
            }
        }
    }
}

.main-page {
    @media only screen and (max-width: 768px) {
        .inner {
            .title {
                margin-top: 0px;
            }
        }
    }
}
</style>