<template>
    <div class="logo-thumbnail logo-custom-css">
        <router-link class="logo-light" to="/">
            <!-- <img :src="require(`@/assets/images/logo/logo-white.png`)" alt="nft-logo"> -->
            <svg width="124" height="22" viewBox="0 0 124 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 0.411621L12.284 21.5881L13.9882 18.9455L8.91124 10.3392H12.8166L15.7988 15.4816L17.3254 12.8032L11.6095 2.73282H15.4083L19.2071 9.33931L20.8047 6.55387L18.568 2.73282H22.7574L24 0.411621H7.02959L11.2189 7.58948H7.31361L3.19527 0.411621H0Z"
                    fill="white" />
                <g clip-path="url(#clip0_330_5197)">
                    <path
                        d="M35.49 5.57471V10.1247H41.02V5.57471H42.51V16.4347H41.02V11.3447H35.49V16.4347H34V5.57471H35.49Z"
                        fill="white" />
                    <path d="M50.7302 5.57471V16.4347H49.2402V5.57471H50.7302Z" fill="white" />
                    <path
                        d="M66.1898 15.9448C65.5298 16.1848 64.2098 16.5448 62.6698 16.5448C60.9398 16.5448 59.5098 16.1248 58.3898 15.1048C57.3998 14.2048 56.7998 12.7648 56.7998 11.0748C56.7998 7.83484 59.1498 5.46484 62.9798 5.46484C64.2998 5.46484 65.3498 5.74484 65.8398 5.96484L65.4698 7.10484C64.8598 6.84484 64.0998 6.64484 62.9398 6.64484C60.1598 6.64484 58.3498 8.28484 58.3498 11.0048C58.3498 13.7248 60.0898 15.3848 62.7698 15.3848C63.7398 15.3848 64.3898 15.2548 64.7298 15.0948V11.8648H62.4198V10.7448H66.1898V15.9548V15.9448Z"
                        fill="white" />
                    <path
                        d="M74.0999 5.57471V10.1247H79.6299V5.57471H81.1199V16.4347H79.6299V11.3447H74.0999V16.4347H72.6099V5.57471H74.0999Z"
                        fill="white" />
                    <path
                        d="M87.8601 16.4247V5.57471H89.4801L93.1401 11.0647C93.9801 12.3347 94.6401 13.4747 95.1801 14.5947L95.2201 14.5747C95.0901 13.1247 95.0601 11.8047 95.0601 10.1247V5.57471H96.4501V16.4347H94.9501L91.3101 10.9347C90.5201 9.71471 89.7501 8.48471 89.1801 7.30471L89.1301 7.32471C89.2201 8.69471 89.2401 10.0047 89.2401 11.8047V16.4447H87.8501L87.8601 16.4247Z"
                        fill="white" />
                    <path
                        d="M109.39 6.74467H103.21V5.53467H109.39V6.74467ZM109.39 11.3947H103.21V10.1847H109.39V11.3947ZM109.39 16.4347H103.21V15.2247H109.39V16.4347Z"
                        fill="white" />
                    <path d="M118.19 6.75471H114.71V5.57471H123.18V6.75471H119.68V16.4247H118.19V6.75471Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_330_5197">
                        <rect width="89.17" height="11.07" fill="white" transform="translate(34 5.46484)" />
                    </clipPath>
                </defs>
            </svg>

        </router-link>
        <router-link class="logo-dark" style="color: #181c32;" to="/">
            <!-- <img :src="require(`@/assets/images/logo/logo-dark.png`)" alt="nft-logo"> -->
            <svg width="124" height="22" viewBox="0 0 124 22" style="filter: invert(1);" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 0.411621L12.284 21.5881L13.9882 18.9455L8.91124 10.3392H12.8166L15.7988 15.4816L17.3254 12.8032L11.6095 2.73282H15.4083L19.2071 9.33931L20.8047 6.55387L18.568 2.73282H22.7574L24 0.411621H7.02959L11.2189 7.58948H7.31361L3.19527 0.411621H0Z"
                    fill="white" />
                <g clip-path="url(#clip0_330_5197)">
                    <path
                        d="M35.49 5.57471V10.1247H41.02V5.57471H42.51V16.4347H41.02V11.3447H35.49V16.4347H34V5.57471H35.49Z"
                        fill="white" />
                    <path d="M50.7302 5.57471V16.4347H49.2402V5.57471H50.7302Z" fill="white" />
                    <path
                        d="M66.1898 15.9448C65.5298 16.1848 64.2098 16.5448 62.6698 16.5448C60.9398 16.5448 59.5098 16.1248 58.3898 15.1048C57.3998 14.2048 56.7998 12.7648 56.7998 11.0748C56.7998 7.83484 59.1498 5.46484 62.9798 5.46484C64.2998 5.46484 65.3498 5.74484 65.8398 5.96484L65.4698 7.10484C64.8598 6.84484 64.0998 6.64484 62.9398 6.64484C60.1598 6.64484 58.3498 8.28484 58.3498 11.0048C58.3498 13.7248 60.0898 15.3848 62.7698 15.3848C63.7398 15.3848 64.3898 15.2548 64.7298 15.0948V11.8648H62.4198V10.7448H66.1898V15.9548V15.9448Z"
                        fill="white" />
                    <path
                        d="M74.0999 5.57471V10.1247H79.6299V5.57471H81.1199V16.4347H79.6299V11.3447H74.0999V16.4347H72.6099V5.57471H74.0999Z"
                        fill="white" />
                    <path
                        d="M87.8601 16.4247V5.57471H89.4801L93.1401 11.0647C93.9801 12.3347 94.6401 13.4747 95.1801 14.5947L95.2201 14.5747C95.0901 13.1247 95.0601 11.8047 95.0601 10.1247V5.57471H96.4501V16.4347H94.9501L91.3101 10.9347C90.5201 9.71471 89.7501 8.48471 89.1801 7.30471L89.1301 7.32471C89.2201 8.69471 89.2401 10.0047 89.2401 11.8047V16.4447H87.8501L87.8601 16.4247Z"
                        fill="white" />
                    <path
                        d="M109.39 6.74467H103.21V5.53467H109.39V6.74467ZM109.39 11.3947H103.21V10.1847H109.39V11.3947ZM109.39 16.4347H103.21V15.2247H109.39V16.4347Z"
                        fill="white" />
                    <path d="M118.19 6.75471H114.71V5.57471H123.18V6.75471H119.68V16.4247H118.19V6.75471Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_330_5197">
                        <rect width="89.17" height="11.07" fill="white" transform="translate(34 5.46484)" />
                    </clipPath>
                </defs>
            </svg>

        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Logo'
}
</script>