<template>
    <div class="rn-service-area rn-section-gapTop" id="rate">
        <div class="container">
            <div class="row">
                <div class="col-12 mb--60">
                    <h3 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">Уникальные условия
                    </h3>
                </div>
            </div>
            <div class="row g-5">
                <template v-for="(service, index) in serviceList" :key="`service-${index}`">
                    <div v-if="index < serviceLimit" :class="serviceColumn">
                        <service-card :service-data="service" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceCard from '@/components/service/ServiceCard'

export default {
    name: 'ServiceStyleOne',
    components: { ServiceCard },
    props: {
        serviceLimit: {
            type: Number,
            default: 4
        },
        serviceColumn: {
            type: String,
            default: 'col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    image: require(`@/assets/images/icons/transfer.svg`),
                    // subtitle: 'Максимальная производительность',
                    title: 'Помощь с переездом',
                    description: `Бесплатный перенос сайтов и приложений на наши серверы в течение 2 недель
<br><br>
Современное оборудование для любых нужд и быстрое развертывание`,
                    // link: '/connect'
                },
                {
                    image: require(`@/assets/images/icons/free.svg`),
                    // subtitle: 'Step-02',
                    title: '30 дней бесплатно',
                    description: `Тестируйте наши услуги без ограничений в течение 30 дней
<br><br>

Моментальный доступ и гибкость конфигураций под любые требования`,
                    // link: '/collection'
                },
                {
                    image: require(`@/assets/images/icons/profit.svg`),
                    // subtitle: 'Step-03',
                    title: `Выгода от сотрудничества до 50%`,
                    description: `Получите до 50% выгоды на аренду серверов с персонализированным подходом 
<br><br>

Гибкие контракты и выгодные условия аренды `,
                    // link: '/connect'
                },
                {
                    image: require(`@/assets/images/icons/support.svg`),
                    // subtitle: 'Step-04',
                    title: `Техническая поддержка 24/7`,
                    description: `Наши эксперты доступны круглосуточно для решения любых вопросов
<br><br>

Премиум-обслуживание и индивидуальные консультации для вашего бизнеса`,
                    // link: '/creator'
                }
            ],
        }
    }
}
</script>